body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  width: 200px;
  height: 67px;
  pointer-events: none;
  align-self: flex-start;
}

.App-header {
  position: relative;
  margin: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: normal;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.mainbody {
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

nav a {
  margin-right: 10px;
}

table {
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt
}

a {
  color: #336699;
  text-decoration: none;
}

.banner {
  display: flex;
  margin: 0 10px;
  height: 27px;
  background-color: #4682B4;
}

.title {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 1.15em;
  font-weight: bold;
  color: white;
  vertical-align: top;
}

.SectionHeader {
  border-left: solid 1px #c0c0c0;
  border-top: solid 1px #c0c0c0;
  border-right: solid 1px #c0c0c0;
  border-bottom: dotted 1px #c0c0c0;
  padding: 2px 2px 2px 2px;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  font-weight: bold;
  color: #666666;
}

.SectionHeader td {
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  font-weight: bold;
  color: #666666;
}

.domains-gif {
  padding-right: 5px;
}

.SectionTable {
  background: url("assets/SectionTableBackground.jpg") repeat-x;
}

.SectionBody {
  border-left: dotted 1px #c0c0c0;
  border-bottom: dotted 1px #c0c0c0;
  border-right: dotted 1px #c0c0c0;
  padding: 0 2px 2px 2px;
}

.HeaderRow td {
  border-bottom: solid 1px #eeeeee;
  font-weight: bold;
  color: #808080;
}

input {
  background: #eeeeee;
  border: solid 1px #c0c0c0;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt;
}

.buttonLink {
  background: #eeeeee;
  border: solid 1px #c0c0c0;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt;
  text-decoration: none;
  color: #000000;
  padding: 1px 4px 1px 4px;
}

.HeaderRow th {
  border-bottom: solid 1px #eeeeee;
  color: #808080;
}

.Row td {
  vertical-align: top;
}

.AlternateRow {
  background: #eeeeee;
}

.AlternateRow td {
  vertical-align: top;
}

.Row:hover, .AlternateRow:hover {
  background: #ffffcc;
  color: #000000;
}

.AlternateRow td div a, .Row td div a {
  display: block;
  width: 100%;
}

.subMenuTable {
  background: #eeeeee url(assets/PageMenuBG.jpg) repeat-x;
  margin: 0 10px;
  border: solid 1px #c0c0c0;
  color: #666666;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 30px;
}

.subMenuLeftCell, .subMenuRightCell {
  padding: 3px 3px 3px 3px;
  color: #000000;
}

.subMenuTable a {
  color: #000000;
  text-decoration: none;
}

.subMenuTable a:hover {
  color: #666666;
}

.subMenuTable select {
  background: #ffffff;
  font-size: 8pt;
}

.textBox {
  background: #eeeeee;
  border: solid 1px #c0c0c0;
  font-size: 8pt;
  width: 100%;
  box-sizing: border-box;
}
.Save {
  background: #ffffff;
  border: solid 1px #c0c0c0;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt;
  font-weight: bold;
  color: #336699;
  cursor: pointer;
}
.Save:hover .Impersonate-Button:hover {
  background: #c0c0c0;
  border: solid 1px #808080;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.Impersonate-Form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Impersonate {
  align-self: center;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  color: crimson;
}

.Impersonate-Error {
  align-self: center;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  color: crimson;
}

.Impersonate-Error:hover {
  cursor: pointer;
}

.Impersonate-Input {
  background: #eeeeee;
  border: solid 1px #c0c0c0;
  font-size: 8pt;
  width: 10%;
  box-sizing: border-box;
  align-self: flex-end;
}

.Impersonate-Button {
  background: #ffffff;
  border: solid 1px #c0c0c0;
  font-family: Trebuchet MS, Tahoma, Arial, Helvetica, sans-serif;
  font-size: 8pt;
  font-weight: bold;
  color: #336699;
  cursor: pointer;
  align-self: flex-end;
}
